export const odinNavbarLogo = (
    <svg
        width="30"
        height="45"
        viewBox="0 0 30 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_172_2)">
            <path
                d="M14.9188 0C6.67952 0 0 6.79505 0 15.1769C0 15.4206 0.00647519 15.6621 0.0172672 15.9029H3.39084C3.37573 15.6585 3.36782 15.4126 3.36782 15.1644C3.36782 8.64824 8.5602 3.36605 14.9656 3.36605C21.371 3.36605 26.5634 8.64824 26.5634 15.1644C26.5634 21.6806 21.3804 26.9526 14.9836 26.9628V30.353C23.1927 30.3172 29.837 23.5368 29.837 15.1776C29.837 6.81847 23.1582 0 14.9188 0Z"
                fill="white"
            />
            <path
                d="M0.0646973 13.6589H11.9999V16.0142C11.9999 16.6077 11.5258 17.0901 10.9423 17.0901H0.865463C0.423711 17.0901 0.0646973 16.7248 0.0646973 16.2755V13.6589Z"
                fill="white"
            />
            <path
                d="M12 39.8071V27.8894C12 27.3917 12.3971 26.9877 12.8864 26.9877H15.3729V39.8481C15.3729 40.0318 15.2261 40.1811 15.0455 40.1811H12.3684C12.1648 40.1811 12 40.0135 12 39.8064V39.8071Z"
                fill="white"
            />
            <path
                d="M18.9442 18.0196C20.3568 18.0196 21.5019 16.8547 21.5019 15.4177C21.5019 13.9807 20.3568 12.8157 18.9442 12.8157C17.5316 12.8157 16.3865 13.9807 16.3865 15.4177C16.3865 16.8547 17.5316 18.0196 18.9442 18.0196Z"
                fill="white"
            />
            <path
                d="M13.7347 45C14.7809 45 15.629 44.1372 15.629 43.0729C15.629 42.0086 14.7809 41.1458 13.7347 41.1458C12.6885 41.1458 11.8403 42.0086 11.8403 43.0729C11.8403 44.1372 12.6885 45 13.7347 45Z"
                fill="white"
            />
            <path
                d="M9.92502 27.077H7.69252C7.47199 27.077 7.29321 27.2589 7.29321 27.4832V38.4267C7.29321 38.6511 7.47199 38.833 7.69252 38.833H9.92502C10.1455 38.833 10.3243 38.6511 10.3243 38.4267V27.4832C10.3243 27.2589 10.1455 27.077 9.92502 27.077Z"
                fill="white"
            />
            <path
                d="M19.0614 38.8329H17.9477C17.3995 38.8329 16.9548 38.3806 16.9548 37.8229V31.1245C17.4959 31.0213 18.0657 30.8946 18.66 30.7387C19.124 30.6172 19.5665 30.4877 19.9859 30.353V37.8924C19.9859 38.4121 19.5723 38.8329 19.0614 38.8329V38.8329Z"
                fill="white"
            />
            <path
                d="M5.94699 24.9574H3.71449C3.49396 24.9574 3.31519 25.1393 3.31519 25.3636V36.3071C3.31519 36.5315 3.49396 36.7133 3.71449 36.7133H5.94699C6.16752 36.7133 6.34629 36.5315 6.34629 36.3071V25.3636C6.34629 25.1393 6.16752 24.9574 5.94699 24.9574Z"
                fill="white"
            />
            <path
                d="M23.4186 36.9058H22.3049C21.7566 36.9058 21.312 36.4535 21.312 35.8958V29.7756C22.4279 29.3239 23.4545 28.6711 24.3424 27.8484V35.9661C24.3424 36.4857 23.9287 36.9066 23.4179 36.9066L23.4186 36.9058Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_172_2">
                <rect width="29.837" height="45" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
