import React from 'react'
import DemoVideo from './DemoVideo/DemoVideo'

import './Home.css'

export default function Home() {
    return (
        <div className="home">
            <div className="what-we-can-do">
                <h2 className="what-we-can-do-title">What We Can Do For You</h2>

                <div className="what-we-can-do-text">
                    If you’re like us and constantly consume social media, this
                    app will definitely make your life easier. It basically
                    finds everything you want to know about the content you are
                    watching. So far, Odin allows you to find the full video of
                    clips taken from YouTube.
                </div>
                <div className="what-we-can-do-text">
                    More features such as finding the full episode from a clip
                    of a TV series or movie are coming soon!
                </div>
                <div className="what-we-can-do-text">
                    We are still in the development and testing
                    stages, so the app isn’t public. If you are interested, send
                    us an email at: odin@odin.com describing why you want to use
                    the app, and we’ll get back to you on if you can be one of
                    our first users and test our beta release!
                </div>
            </div>
            <div className="vision-wrapper">
                <div></div>
                <div className="vision-do">
                    <h2 className="vision-title">Our Vision</h2>
                    <div className="vision-text">
                        Odin aims to help users find information about the
                        content they are consuming on social media effortlessly.
                    </div>
                </div>
            </div>
            <div className="app-demo">
                <h2 className="app-demo-title">App Demo</h2>
                <DemoVideo videoId="-EjZrOgHkGw" />
            </div>
        </div>
    )
}
