import React from 'react'

import './TeamMember.css'

export default function TeamMember({ picture = '', name }) {
    return (
        <div className="team-member">
            <div className="team-member-picture-wrapper">
                {picture !== '' ? (
                    <img className="team-member-picture" src={picture} alt="" />
                ) : (
                    <div className="team-member-picture"></div>
                )}
            </div>
            <div className="team-member-name">{name}</div>
        </div>
    )
}
