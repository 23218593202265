import React from 'react'

import './AboutUs.css'
import TeamMember from './TeamMember/TeamMember'
import alex from '../../images/alex.png'

export default function AboutUs() {
    return (
        <div className="about-us">
            <h2 className="about-us-title">About Us</h2>
            <div className="about-us-text">
                At Odin we have a team of talented individuals with expertise in
                different domains like Software development, UI UX Design, App
                development and much more. Our team is diverse and globalized
                with students from the University of Toronto, American
                University of Beirut and Concordia University. We are working
                together to realize Odin!
            </div>
            <div className="meet-the-team">
                <h2 className="meet-the-team-title">Meet The Team</h2>
                <div className="team-members">
                    <div className="first-row-team">
                        <TeamMember name="James Rupert" />
                        <TeamMember name="Ghadi Sleiman" />
                        <TeamMember name="Suleiman Najim" />
                    </div>
                    <div className="second-row-team">
                        <TeamMember name="Alex Khalil" picture={alex} />
                        <TeamMember name="Elias Chaptini" />
                        <TeamMember name="Zad Ziade" />
                    </div>
                </div>
            </div>
        </div>
    )
}
