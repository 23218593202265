import React from 'react'

import './DemoVideo.css'

export default function DemoVideo({ videoId }) {
    return (
        <div className="demo-video">
            <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                width="853"
                height="480"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Odin Demo Video"
            ></iframe>
        </div>
    )
}
