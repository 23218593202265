// import { useState } from 'react'

import Home from './components/Home/Home'
// import Welcome from './components/Welcome/Welcome'
import { Routes, Route, Link } from 'react-router-dom'

import './App.css'
import AboutUs from './components/AboutUs/AboutUs'
import { odinNavbarLogo } from './components/Home/assets/SVGs'

function App() {
    // const [welcome, setWelcome] = useState(true)

    // setTimeout(function () {
    //     setWelcome(false)
    // }, 10000)

    return (
        <div className="App">
            {/* {welcome ? (
                <Welcome />
            ) : (
                <> */}
            <nav className="navbar">
                <div className="nav-logo">{odinNavbarLogo} Odin</div>
                <div className="nav-buttons">
                    <Link to="/" className="link-btn">
                        home
                    </Link>
                    <Link to="/aboutus" className="link-btn">
                        about us
                    </Link>
                </div>
            </nav>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
            </Routes>
            <footer></footer>
            {/* </>
            )} */}
        </div>
    )
}

export default App
